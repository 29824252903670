<template>
  <div>
    <Filtros
      class="mb-5"
      empresas
      funcoes
      indicadores
      colaborador
      action
      @filter="getFilters"
      @input:colaborador="nomeColaborador = $event"
      @change:empresa="
        relatorio.id_empresa = $event.map(empresa => empresa.id_empresa)
      "
      @change:funcao="
        relatorio.id_funcao = $event.map(funcao => funcao.id_funcao)
      "
      @change:indicador="
        relatorio.id_indicador = $event.map(indicador => indicador.id_indicador)
      "
    >
      <RelatorioXlsx :filter="{ ...relatorio }" meta />
    </Filtros>
    <BaseTable
      :headers="headers"
      :items="metas.data"
      :loading="metas.loading"
      :paginate="true"
      :search="nomeColaborador"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Metas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>

      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.id_colaborador }} - {{ item.colaborador | TitleCase }}
      </template>

      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.funcao | TitleCase }}
      </template>

      <template v-slot:[`item.indicador`]="{ item }">
        {{ item.id_indicador }} - {{ item.indicador }}
      </template>

      <template v-slot:[`item.perc_cresc`]="{ item }">
        {{ item.perc_cresc }}%
      </template>

      <template v-slot:[`item.data_inicio`]="{ item }">
        {{ item.data_inicio | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_final`]="{ item }">
        {{ item.data_final | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_proc`]="{ item }">
        {{ item.data_proc | BrazilianStandardDateAndTime }}
      </template>
    </BaseTable>

    <BaseTable
      :headers="headersMetasAgrupadas"
      :items="metasAgrupadas.data"
      :loading="metasAgrupadas.loading"
      :paginate="true"
      :search="nomeColaborador"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Metas agrupadas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>

      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.colaborador | TitleCase }}
      </template>

      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.funcao | TitleCase }}
      </template>

      <template v-slot:[`item.perc_cresc`]="{ item }">
        {{ item.perc_cresc }}%
      </template>
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import campanhaService from "@/services/campanha";
import dateMixin from "@/mixins/dateMixin";
import { mapGetters, mapActions } from "vuex";
import Filtros from "./Filtros";
import RelatorioXlsx from "../RelatorioXlsx";

export default {
  name: "meta-equipe",

  mixins: [dateMixin],

  components: {
    Filtros,
    BaseTable,
    RelatorioXlsx
  },

  props: {
    idTipoMeta: {
      type: Number
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Apelido",
          value: "apelido",
          sortable: true
        },
        { text: "Colaborador", value: "colaborador" },
        { text: "Função", value: "funcao", sortable: true },
        { text: "Cod. Equipe", value: "id_equipe" },
        { text: "Indicador", value: "indicador" },
        {
          text: "Meta Gmr",
          value: "meta_gmr",
          align: "right",
          sortable: true
        },
        {
          text: "Meta Campanha",
          value: "meta_campanha",
          align: "right",
          sortable: true
        },
        {
          text: "% Crescimento",
          value: "perc_cresc",
          align: "right",
          sortable: true
        },
        {
          text: "Data Inicial",
          value: "data_inicio"
        },
        {
          text: "Data Final",
          value: "data_final"
        },
        {
          text: "Data de Processamento",
          value: "data_proc"
        }
      ],
      headersMetasAgrupadas: [
        {
          text: "Apelido",
          value: "apelido",
          sortable: true
        },
        { text: "Colaborador", value: "colaborador" },
        { text: "Função", value: "funcao", sortable: true },
        { text: "Indicador", value: "indicador" },
        {
          text: "Meta Gmr total",
          value: "meta_gmr_total",
          align: "right",
          sortable: true
        },
        {
          text: "Meta Campanha Total",
          value: "meta_campanha_total",
          align: "right",
          sortable: true
        },
        {
          text: "% Crescimento",
          value: "perc_cresc",
          align: "right",
          sortable: true
        }
      ],
      metas: {
        loading: false,
        total: 0,
        lastPage: 1,
        options: {
          page: 1,
          perPage: 15
        },
        data: []
      },
      metasAgrupadas: {
        loading: false,
        total: 0,
        lastPage: 1,
        options: {
          page: 1,
          perPage: 15
        },
        data: []
      },
      filter: false,
      filters: {
        id_empresa: "",
        id_funcao: "",
        id_indicador: ""
      },
      nomeColaborador: "",
      relatorio: {
        id_empresa: "",
        id_tipo_meta: this.idTipoMeta,
        id_funcao: "",
        id_indicador: ""
      }
    };
  },

  mounted() {
    this.buscaMetaEquipes();
    this.buscarMetaEquipesAgrupadas();
    this.fetchIndicadores({ per_page: -1, ativa_campanha: "S" });
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha",
      getMetaEquipe: "campanha/getMetaEquipe"
    })
  },

  methods: {
    ...mapActions({
      fetchMetaEquipe: "campanha/fetchMetaEquipe",
      fetchIndicadores: "campanha/fetchIndicadores"
    }),

    async buscaMetaEquipes() {
      this.metas.loading = true;
      try {
        await this.fetchMetaEquipe({
          id_tipo_meta: 3,
          id_campanha: this.getCurrentCampanha,
          per_page: -1
        });
        this.metas.data = this.getMetaEquipe.data.sort((a, b) => new Date(a.data_inicio) - new Date(b.data_inicio));
        this.metas.total = this.getMetaEquipe.total;
        this.metas.lastPage = this.getMetaEquipe.last_page;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possivel buscar os dados"
        });
        this.metas.loading = false;
      } finally {
        this.metas.loading = false;
      }
    },

    async buscarMetaEquipesAgrupadas() {
      this.metasAgrupadas.loading = true;

      try {
        const { data } = await campanhaService.getMetasAgrupadas({
          id_campanha: this.getCurrentCampanha,
          id_tipo_meta: 3,
          per_page: -1
        });
        this.metasAgrupadas.data = data.sort((a, b) => new Date(a.data_inicio) - new Date(b.data_inicio));;
        this.metas.total = this.getMetaEquipe.total;
        this.metas.lastPage = this.getMetaEquipe.last_page;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possivel buscar os dados"
        });
        this.metasAgrupadas.loading = false;
      } finally {
        this.metasAgrupadas.loading = false;
      }
    },

    getFilters(data) {
      const { empresas, funcoes, indicadores, filter } = data;
      this.metas.options.page = 1;
      this.filter = filter;

      this.filters = {
        id_empresa: empresas,
        id_funcao: funcoes,
        id_indicador: indicadores
      };

      this.getMetasFiltradas();
    },

    async getMetasFiltradas() {
      this.metas.loading = true;

      try {
        await this.fetchMetaEquipe({
          id_tipo_meta: 3,
          id_campanha: this.getCurrentCampanha,
          per_page: -1,
          ...this.filters
        });

        const { data } = await campanhaService.getMetasAgrupadas({
          id_campanha: this.getCurrentCampanha,
          id_tipo_meta: 3,
          per_page: -1,
          ...this.filters
        });

        this.metasAgrupadas.data = data;
        this.metas.total = this.getMetaEquipe.total;
        this.metas.lastPage = this.getMetaEquipe.last_page;

        this.metas.data = this.getMetaEquipe.data;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possivel buscar os dados"
        });
        this.metas.loading = false;
      } finally {
        this.metas.loading = false;
      }
    }
  }
};
</script>
